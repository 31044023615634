<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updatePackage)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <packages-form
          :package-data="packageData"
          :for-hala-cashier-options="forHalaCashierOptions"
          :status-options="statusOptions"
        />

        <h4 class="mb-1 mt-2">
          Subscriptions
        </h4>

        <subscriptions-create
          :package-data="packageData"
          :tenure-options="tenureOptions"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import PackagesForm from '../packages-form/PackagesForm.vue'
import usePackagesForm from '../packages-form/usePackagesForm'
import SubscriptionsCreate from '../subscriptions-create/SubscriptionsCreate.vue'
import { statusOptions, tenureOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    PackagesForm,
    SubscriptionsCreate,

    //  Form Validation
    ValidationObserver,
  },

  setup() {
    const {
      packageData,

      forHalaCashierOptions,

      fetchPackage,
      updatePackage,
    } = usePackagesForm()

    const { callTenure } = useSystemCodes()
    callTenure()

    fetchPackage()

    return {
      //  Data
      packageData,

      //  Options
      tenureOptions,
      forHalaCashierOptions,
      statusOptions,

      //  Action
      fetchPackage,
      updatePackage,
    }
  },
}
</script>
